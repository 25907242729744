html, body{
  /* background-color:#000; */
  background: #fff;
  /* background-color: #bbe4e9; */
  scroll-behavior: smooth;
  
}
.jumbotron {
  border-radius: 0px !important;
  padding-top: 4rem !important;
}
.jumbotron-text-align{
  min-width:243px;
  /* padding: auto; */
  text-align: center;
}
#jumbotron-headline{
  padding-top: 4rem;
  font-size:2.5rem;
  color: #fff;
}
@media (max-width: 350px) {
  #jumbotron-headline{
    font-size: 2rem;
  }
}
@media (min-width: 440px) {
  .jumbotron-text-align {
    text-align: center;
    /* padding-left: 4rem; */
  }
}
@media (min-width: 800px){
  .jumbotron-text-align {
    text-align: left;
    /* padding-right: calc(100% - 605px) ; */
    padding-left: calc((100% - 605px) / 3) ;
  }
}
.jumbotron-bg-overlay{
    position: relative !important;
    /* background-color: rgba(105, 105, 105, 0.541) !important; */
    background-color: rgba(0,0,0,0.5) !important;
    /* background-color: rgba(255, 255, 230, 0.5) !important; */
    height: 500px !important;
    padding-left: "0px" !important;
    padding-right: "0px" !important;
    padding: 4rem 0;
  }
.bg-dark-nav{
  /* background-color: #000 !important; */
  background-color: #103853;
  color: #fff !important;
}
@media (max-width: 345px){
  .navbar{
    justify-content: center !important;
  }
}
.navbar-dark .navbar-nav .nav-link {
  /* color: rgba(225,225,225,.8) !important; */
  color: #fff !important;
}

.footer{
  flex-shrink: 0;
  padding: 24px 16px;
  margin-top: auto;
  text-align: center;
  background-color: #103853;
  color: #fff;
}
.page-footer {
  background-color: #0f3753;
  color: #fff;
}

.card{
  width: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  background-color: rgba(140, 98, 38, .125) !important;
  /* border-color: #384141 !important; */
  border-radius: 0;
  /* box-shadow: 4px 4px 10px #8c6226; */
  box-shadow: 0px 0px 15px rgb(53 77 93 / 70%);
  color: #fff;
  /* transition: width 2s;
  transition-timing-function: ease; */
  transition: all .2s ease-in-out;
}
.card:hover{
  /* width: 200px; */
  cursor: pointer;
  transform: scale(1.05);
}
.card:hover .card-title{
  /* display: none; */
  /* color:#0f3753; */
}
.card-img {
  
    border-top-left-radius: 0;
    border-top-right-radius: 0;

}
.card-container {
  margin: 15px 0px;
}
.slider .card-container{
  margin: 15px 5px;
  width: 200px;
}
.card-body{
  /* padding: 1rem !important; */
  height: 70px;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0 1rem !important;
  background: linear-gradient(0deg, rgba(16,56,83,1) 0%, rgba(53,77,93,0.7) 35%, rgba(255,255,255,0) 90%);
  display: flex;
  justify-content: center;
  transition: all .2s ease-in-out;
}
.card:hover .card-body{
  height: 0;
  /* background: linear-gradient(0deg, rgba(16,56,83,1) 0%, rgba(53,77,93,0.7) 35%, rgba(255,255,255,0) 90%); */
}
.card-title{
  margin-bottom: .5rem;
  position: absolute;
  bottom: 0px;
  transition: all 1s ease-in-out;
  text-align: center;
}

.card-subtitle{
  display: flex;
  align-items: flex-end;
  /* display: none; */
  opacity: 0;
  /* transition: all 1s ease-in-out; */
  transition: opacity 0.2s linear;
}
.card:hover .card-subtitle{
  /* display: block; */
  opacity: 1;
}

.ModalGloves{
  font-weight: bold;
  margin-bottom: 0px !important;
  font-size: 1rem;
  width: calc(100% - 2em);
  color: #fff;
  position: absolute;
  bottom: 0;
  text-align: center;
}


.modal-body{
  display: block;
  
}
@media (min-width: 644px) {
  .modal-body{
    display: inline-flex;
  }
}
.infomodal-img{
  text-align: center;
}
@media (min-width: 644px) {
  .infomodal-img{
    text-align: right;
  }
}
.infomodal-text{
  margin: auto;
  /* margin-top: 20px; */
}
@media (min-width: 644px) {
  .infomodal-text{
    margin-left: 50px;
  }
}
.img-responsive-infomodal {
  margin: 0 auto;
  max-height: 300px;
}
@media (min-width: 644px) {
  .img-responsive-infomodal{
    /* display: none; */
  }
}
.feature-wrap {
  padding: 12px;
  width: 276px;
  max-width: 276px;
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.section-search{
  padding: 1rem 15px;
  margin: auto !important;
  /* max-width: 1080px; */
  width: 100%;
  max-width: 306px;
}
@media (min-width: 644px) {
  .section-search{
    padding: 1rem 31px;
    width: 100%;
    max-width: 644px;
  }
}
@media (min-width: 950px){
  .section-search {
    width: 100%;
    max-width: 950px;
  }
}
.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  /* padding: 1rem 1rem; */
  margin: auto !important;
  /* max-width: 1080px; */
  width: 100%;
  max-width: 306px;
  /* border: 1px solid red; */
}

@media (min-width: 644px) {
  .card-deck {
    padding: 1rem 1rem;
    width: 100%;
    max-width: 644px;
  }
}
@media (min-width: 950px){
  .card-deck {
    
    width: 100%;
    max-width: 950px;
  }
}

.dropdown-lang {
  background-color: #ccc;
}

@media(min-width: 767px){
  .dropdown-lang{
    background-color: #222;
  }
}
.input-group {
  border-radius: .25rem;
  box-shadow: 0 0 5px #8b6226;
}
.input-group-text, .form-control{
  border-color: #384141 !important;
}

.input-group-text {
  color: #384141 !important;
  background-color: #e9e1d7 !important;
}
.btn:hover {
  color: #212529;
  text-decoration: none;
  border-color: #212529;
}
.btn-secondary {
  color: #fff;
  background-color: #969fa2 !important;
  border-color: #969fa2 !important;
}
.btn.btn-black {
  color: #fff;
  background-color: #8c6226;
}

.footer-copyright {
  color: #bbe4e8;
}

#modal-button-container button{
  background-color: #384141 !important;
  border-color: #6ea4b6 !important;
}
#modal-button-container button:hover {
  color: #6ea4b6;
  text-decoration: none;
  border-color: #282A22 !important;
  background-color: #282A22 !important;
}
#modal-button-container button:focus{
  box-shadow: 0 0 0 0.2rem rgba(130,138,145,.5);
}

#video_overlays {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.46);
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
#video_box{position: relative;}

.jumbotron-video {
  width: 100%;
  max-height: 500px;
  float: right;
}
@media (min-width: 644px) {
  .jumbotron-video {
    width: 90%;
    max-height: 500px;
    float: right;
  }
}
@media (min-width: 850px){
  .jumbotron-video {
    
    width: 80%;
    max-width: 950px;
    float: right;
  }
}
@media (min-width: 950px){
  .jumbotron-video{
    
    height: 500px;
    max-width: 950px;
    float: right;
  }
}

/* slider */
.slider {
  /* width: 100%;
  max-width: 1080px;
  height: 250px;
  position: relative;
  margin: auto; */
  /* overflow-x: scroll; */
  /* overflow-x: hidden;
  overflow-y: hidden; */
}

.slider::-webkit-scrollbar {
  display: none;
}

.slider .slide {
  display: flex;
  flex-wrap: wrap;
  transition: 0.3s left ease-in-out;
}

.slider .item {
  margin-right: 10px;
}

.slider .item:last-child {
  margin-right: 0;
}

.ctrl {
  text-align: center;
  margin-top: 5px;
}

.ctrl-btn {
  padding: 20px 20px;
  min-width: 50px;
  /* background: #fff; */
  background: transparent;
  border: none;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  outline: none;

  position: absolute;
  top: 50%;
  margin-top: -27.5px;
}

.ctrl-btn.pro-prev {
  left: 0;
}

.ctrl-btn.pro-next {
  right: 0;
}

.about-bg{
  min-height: 400px;
  max-height:400px;
  width:100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.about-overlay {
  height: 400px;
  background: linear-gradient(90deg, rgba(50,50,50,1) 0%, rgba(53,77,93,0.9) 70%, rgba(255,255,255,0.3) 90%);
  color: #fff;
  /* background: linear-gradient(90deg, rgba(16,56,83,1) 0%, rgba(53,77,93,0.7) 35%, rgba(255,255,255,0) 90%); */
}
#about .section-slider {
  padding: 30px 50px ;
}
@media (max-width: 519px){
  #about .section-slider {
    padding: 30px 0 ;
  }
}
.about-text {
  padding: 10px 50px 10px 20px;
}
@media (max-width: 520px){
  .about-text {
    padding: 10px 0px 10px 0px;
  }
  
}

.tc-gloves-poster {
  width: 100%;
  background-color: #000028;
  color: #fff;
  padding: 1em;

  background-image:  
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%), 
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%), 
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%);
  background-size: 4px 4px, 3px 3px, 5px 5px, 3px 3px, 6px 6px, 4px 4px, 5px 5px, 4px 4px, 6px 6px, 3px 3px, 5px 5px, 4px 4px, 3px 3px, 6px 6px, 5px 5px, 4px 4px, 5px 5px, 6px 6px;
  background-position: 20% 10%, 5% 20%, 10% 75%, 22% 35%, 30% 30%, 40% 50%, 45% 20%, 65% 20%, 85% 30%, 98% 90%, 20% 80%, 50% 80%, 75% 80%, 89% 75%, 90% 95%, 60% 70%, 65% 40%, 90% 20% ;
  background-repeat: no-repeat;
  animation: twinkle ease-in 0.3s infinite;
}
@keyframes twinkle{
  to{
  background-image:  
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, #fff 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%), 
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, #fff 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, #fff 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, #fff 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, #fff 20%, transparent 30%), 
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, #fff 20%, transparent 30%),
  radial-gradient(circle, rgba(255,255,255,0.7) 20%, transparent 30%),
  radial-gradient(circle, #fff 20%, transparent 30%),
  radial-gradient(circle, #fff 20%, transparent 30%);  }
}
.tc-gloves-poster.text {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.tc-gloves-poster .image-container {
  display: flex;
  align-items: end;
}
@media (max-width: 644px) {
  .tc-gloves-poster .image-container {
    display: block;
    text-align: center;
  }
}
.img-responsive.tc-gloves {
  margin-left: auto;
  width: 400px;
}
@media (max-width: 644px) {
  .img-responsive.tc-gloves {
    margin: auto;
    width: 240px;
  }
}

.tc-gloves-poster .image-container .contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.highlight-yellow {
  display: inline-block;
}
.highlight-container, .highlight {
  position: relative;
}
.highlight-yellow:before  {
  
  content: " ";
  display: block;
  height: 90%;
  width: 100%;
  margin-left: -3px;
  margin-right: -3px;
  position: absolute;
  background: #ffd500;
  transform: rotate(2deg);
  top: -1px;
  left: -1px;
  border-radius: 20% 25% 20% 24%;
  padding: 10px 3px 3px 10px;
}

.big-title {
  font-size: 6rem;
}
@media (max-width: 644px) {
  .big-title {
    font-size: 4rem;
  }
}